section.slider {
    display: flex;
    justify-content: center;
    height: 40.4vh;
}
.sliderimages{
    width: 1500px;
    height: 300px;
    border-radius: 10px;

}
.right-arrow{
    position: absolute;
    top: 25%;
    right: 32px;
    font-size: 2rem;
    color: black;
}
.left-arrow{
    position: absolute;
    top: 25%;
    left: 32px;
    font-size: 2rem;
    color: black;
}